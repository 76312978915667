#wolf{
    md-content{
        margin-bottom: 1em;
    }

    table{
        width: 100%;

        td,th{
            width: 50%;
            font-size: 20px;
        }
        
        td{
            border-bottom: 1px solid lightgray;
        }
    }
}

#editarWolf{
    max-height: 95vh;

    .md-errors-spacer{
        display: none;
    }

    md-checkbox{
        margin: 16px auto;
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {
    #editarWolf{
        max-height: 100vh;
    }
}