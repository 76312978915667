#flow-details{
    md-content{
        margin-bottom: 1em;
    }

    .nombre-flow{
        font-size: 24px;
        padding: 0 10px;
        border-radius: 1em 0 0 1em;
    }

    .temperatura{
        font-size: 18px;
        margin-left: 10px;
        padding: 0 10px;
        border-radius: 0 1em 1em 0;
    }

    table{
        width: 50%;

        td,th{
            font-size: 20px;
        }

        tr:not(:last-child) td, tr th{
            border-bottom: 1px solid lightgray;
        }

        .md-primary-bg, .md-warn-bg{
            text-align: center;
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {
    #flow-details{
        table{
            width: 100%;
        }
    }
}