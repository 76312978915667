#wolf-details{
    md-content{
        margin-bottom: 1em;
    }

    .nombre-wolf{
        font-size: 24px;
        padding: 0 10px;
        border-radius: 1em 0 0 1em;
    }

    table{
        width: 100%;

        td,th{
            font-size: 20px;
        }

        tr:not(:last-child) td, tr th{
            border-bottom: 1px solid lightgray;
        }

        .md-primary-bg, .md-warn-bg{
            text-align: center;
        }
    }

    .icon-television{
        color: green !important;
    }

    .icon-radio{
        color: orangered !important;
    }

    .icon-quality-high{
        color: mediumturquoise !important;
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {
    #wolf-details{
        table{
            td,th{
                font-size: 15px;
            }
        }
    }
}