#flow{
    md-content{
        margin-bottom: 1em;
    }

    table{
        width: 100%;

        td,th{
            width:33.33%;
            font-size: 20px;
        }

        td{
            border-bottom: 1px solid lightgray;
        }
        
        tr.inactive {
            background: lightgray;
        }
        
        .icon-comment-check {
            color: green;
        }
        
        .icon-comment-remove-outline {
            color: red;
        }
        
        .icon-information {
            color: darkblue;
        }
    }
}

#editarFlow{
    max-height: 95vh;

    .md-errors-spacer{
        display: none;
    }

    md-checkbox{
        margin: 16px auto;
    }
}

#alarmsFlow {
    table {
        width: 100%;
        border-collapse: collapse;
        
        th, td {
            border: 1px solid #ddd;
            white-space: pre;
            padding: 4px 8px;
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {
    #editarFlow{
        max-height: 100vh;
    }

    #flow{
        table{
            td,th{
                font-size: 15px;
            }
        }
    }
}
